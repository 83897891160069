//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DrawerMenu from "./DrawerMenu";
import Logo from "./common/P/Logo";
import Auth from "./common/P/Auth";
export default {
  data() {
    return {
      drawerMenuIsOpen: false,
      menuItems: [
      // {
      //     link: "/features",
      //     text: "服务能力",
      // },
      {
        link: "/pricing",
        text: "价格"
      }, {
        link: "/contact",
        text: "联系方式"
      }, {
        link: "https://help.salesfinder.cn/salesfinder-plugin",
        text: "浏览器插件"
      }, {
        link: "/partner",
        text: "合作伙伴计划"
      }]
    };
  },
  created() {
    this.$store.dispatch("user/getUser");
  },
  methods: {
    closeDrawerMenu() {
      this.drawerMenuIsOpen = false;
    },
    openDrawerMenu() {
      this.drawerMenuIsOpen = true;
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  components: {
    DrawerMenu,
    Logo,
    Auth
  }
};