//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserDropdown from "@/components/User/Dropdown";
export default {
  props: {
    user: null
  },
  components: {
    UserDropdown
  }
};