//
//
//
//
//
//
//

import Wechat from "@/components/Billing/icons/wechat3.svg?inline";
export default {
  components: {
    Wechat
  }
};